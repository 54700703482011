@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-white;
	color: $text-black;

	min-height: calc(100svh - $promo-height - 70px);

	display: flex;
	justify-content: center;
	align-items: center;

	@include section-padding;

	@include sm-down {
		align-items: flex-start;
		min-height: unset;
	}

	@include xs-only {
		padding-top: 3rem;
		padding-bottom: 0;
	}
}

.content {
	display: grid;
	grid-template-columns: auto 1fr;
	justify-content: center;
	// align-items: center;
	width: 100%;

	@include max-width-center;

	gap: 3rem;

	@include sm-down {
		display: flex;
		flex-direction: column;
		margin-inline: unset;
		align-items: flex-start;
	}
}

.image_container {
	@include xs-only {
		width: calc(100% + $spacing-medium-mobile * 2);
		@include section-padding-inverse-x;
	}
}

.image_container img {
	border-radius: $br-sm;

	@include xs-only {
		border-radius: 0;
		height: 15rem;
	}
}

.content header {
	max-width: 27rem;
}

.content header h1 {
	margin-block-end: 2.5rem;
}

.content header[data-financing-provider='affirm'] {
	svg {
		height: 1em;
		width: unset;
		aspect-ratio: 2.6 / 1;
	}
}

.content header[data-financing-provider='klarna'] {
	svg {
		height: 0.8em;
		width: unset;
		aspect-ratio: 4.6 / 1;
	}
}

.content header[data-financing-provider='truemed'] {
	svg {
		height: 1.6em !important;
		width: unset !important;
		aspect-ratio: 59 / 18;
		transform: translateY(-4.5%);
	}
}

.small_print {
	margin-block-start: 0.5rem;
	color: $text-grey-dark;
}
