@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $background-white;
	color: $text-black;

	@include section-padding;

	display: flex;
	flex-direction: column;
	gap: 14rem;

	@include sm-down {
		gap: 4rem;
	}
}

$content-width: 30rem;
.card {
	display: grid;
	grid-template-columns: 1fr $content-width;
	column-gap: 5rem;
	row-gap: 3rem;
	align-items: center;

	@include max-width-center;

	@include sm-down {
		grid-template-columns: 1fr;
		align-items: start;
		margin-inline: unset;
	}
}

.card:nth-child(even) {
	@include md-up {
		.image {
			order: 1;
		}

		grid-template-columns: $content-width 1fr;

		.content {
			justify-self: end;
		}
	}
}

.image {
	width: 100%;
}

.card ul {
	list-style: none;
	padding: 0;
	margin: 0;

	max-width: 30rem;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.card img {
	height: 500px;
	width: 100%;
	border-radius: $br-sm;

	max-width: 700px;

	@include sm-down {
		height: unset;
		aspect-ratio: 8 / 5;
	}
}

.content {
	display: flex;
	flex-direction: column;
}

.content h2 {
	margin-block-end: 2rem;

	@include sm-down {
		margin-block-end: 0.75rem;
	}
}

.content li p {
	color: $text-grey-dark;
}
